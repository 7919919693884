import _constantClient from "./js/utils/constants";

let isSubscribed = false;

const UrlB64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

//STUB Save the browser endpoint to db
const SaveSubscriptiontoDb = (subs) => {
    subs.expiration = 12 * 60 * 60
    $.ajax({
        url: '/core/push_notification/subscribe',
        type: 'post',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(subs),
        success: function (res) { },
    });
}


export const RegisterSW = async () => {
    if ('serviceWorker' in navigator) {
        try {
            const swRegistration = await navigator.serviceWorker.register("/sw.js");
            if ('PushManager' in window) {
                let subscription = await swRegistration.pushManager.getSubscription();
                isSubscribed = !(subscription === null);
                if (isSubscribed) {
                    SaveSubscriptiontoDb(subscription);
                } else {
                    subscription = await swRegistration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: UrlB64ToUint8Array(_constantClient.NOTIFICATION_KEY)
                    });
                    SaveSubscriptiontoDb(subscription);
                }
            }else{
                console.warn('Push messaging is not supported');
            }
        } catch (error) {
            console.error(error);
        }
    }
}
