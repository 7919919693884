
export default function (siteKey) { 
	return `
<h2 class="h1 font-weight-bold mt-4 text-dark pb-3">
	Set Your Password
</h2>
<p class="text-secondary pb-3">
	Please choose a new password to finish signing in.
</p>

<form>
	<div class="form-group">
		<label for="password-field">New Password</label>
		<div class="input-group mb-2">
			<input type="password" autocomplete="off" id="password-field" required class="form-control password">
			<div class="input-group-append">
			<!-- QPC-8590 default eye icon should be closed-->
				<span toggle="#password-field" class="input-group-text fa fas fa-eye-slash eye-icon view-hidepassword"></span>
			</div>
		</div>
		<div class="text-danger d-none invalid-pattern">
			<p class="small mb-1">Make sure to include at least 8 characters,1 Special Character,1 Uppercase letter,1
				Lowercase letter and 1 number</p>
		</div>

		<div class="text-danger d-none pass-required">
			<p class="small mb-1">Password is required</p>
		</div>
	</div>

	<div class="form-group">
		<label for="confirmpassword">Confirm Password</label>
		<input id="confirmpassword" type="password" class="form-control txtPassword confirmpassword mb-2">
		<div class="text-secondary">This site is protected by reCAPTCHA and the Google
    	<a href="https://policies.google.com/privacy">Privacy Policy</a> and
    	<a href="https://policies.google.com/terms">Terms of Service</a> apply.
  		</div>
		<div class="text-danger d-none password-no-match">
			<p class="small mb-1">The password confirmation does not match</p>
		</div>

		<div class="text-danger d-none conf-required">
			<p class="small mb-1">Confirm password is required</p>
		</div>
		<p class="text-danger set-error pt-2"></p>
		<div class="captchaDiv">
  		</div>
  		<p class="captchaError text-danger"> </p> 
	</div>

	<div class="form-group">
		<button disabled="disabled" type="button" class="btn btn-primary btn-lg btn-block setpassword" data-sitekey=${siteKey}>
			Change Password
		</button>
	</div>
</form>`}